import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { auth } from "utils/firebase";
import {
  getUserData as getUserDataApi,
  setUserDescription as setUserDescriptionApi,
  setUserPublic as setUserPublicApi,
  setEmailConfirmed as setEmailConfirmedApi,
} from "./api";

export const initialState = {
  data: {
    uid: "" as string,
    displayName: "" as string,
    user: "" as string,
    displayPicture: "" as string,
    description: "" as string,
    slug: "" as string,
    active: null,
    isPublic: false,
    isEmailConfirmed: false,
    profileCompletion: {
      displayName: false,
      displayPicture: false,
      linkedServices: false,
    },
    ts: 0 as number,
  },
  loading: {
    signUp: false,
    loggingIn: false,
    getUserData: false,
    setUserDescription: false,
    setUserPublic: false,
    setEmailConfirmed: false,
  },
  error: {
    signUp: null,
    loggingIn: null,
    getUserData: null,
    setUserDescription: null,
    setUserPublic: null,
    setEmailConfirmed: null,
  },
};

export const getUserData = createAsyncThunk("user/getUserData", async () => {
  const user = auth.currentUser;
  if (!user) return Promise.reject();
  await user.reload();
  const updatedUser = auth.currentUser;
  const apiUser = await getUserDataApi(updatedUser.email);
  return {
    uid: updatedUser.uid,
    user: updatedUser.email,
    displayName: updatedUser.displayName || apiUser.displayName,
    displayPicture: updatedUser.photoURL,
    slug: apiUser.slug,
    active: apiUser.active,
    description: apiUser.description,
    isPublic: apiUser.isPublic,
    isEmailConfirmed: apiUser.isEmailConfirmed,
    profileCompletion: apiUser.profileCompletion,
    ts: +new Date(),
  };
});

export const setUserDescription = createAsyncThunk(
  "user/setUserDescription",
  async (description: string) => {
    await setUserDescriptionApi(description);
    return description;
  }
);

export const setUserPublic = createAsyncThunk(
  "user/setUserPublic",
  async (visible: boolean) => {
    await setUserPublicApi(visible);
    return visible;
  }
);

export const setEmailConfirmed = createAsyncThunk(
  "user/setEmailConfirmed",
  async () => {
    await setEmailConfirmedApi();
    return true;
  }
);

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggingIn: (state, action: PayloadAction<boolean>) => {
      state.loading.loggingIn = action.payload;
    },
    setData: (
      state,
      action: PayloadAction<Partial<typeof initialState.data>>
    ) => {
      state.data = { ...state.data, ...action.payload };
    },
    clearData: state => {
      state.data = initialState.data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserData.pending, state => {
        state.loading.getUserData = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.loading.getUserData = false;
        state.data = action.payload ?? initialState.data;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.loading.getUserData = false;
        state.error.getUserData = action.error;
      })
      .addCase(setUserDescription.pending, state => {
        state.loading.setUserDescription = true;
      })
      .addCase(setUserDescription.fulfilled, (state, action) => {
        state.loading.setUserDescription = false;
        state.data.description = action.payload;
      })
      .addCase(setUserDescription.rejected, (state, action) => {
        state.loading.setUserDescription = false;
        state.error.setUserDescription = action.error;
      })
      .addCase(setUserPublic.pending, state => {
        state.loading.setUserPublic = true;
      })
      .addCase(setUserPublic.fulfilled, (state, action) => {
        state.loading.setUserPublic = false;
        state.data.isPublic = action.payload;
      })
      .addCase(setUserPublic.rejected, (state, action) => {
        state.loading.setUserPublic = false;
        state.error.setUserPublic = action.error;
      })
      .addCase(setEmailConfirmed.pending, state => {
        state.loading.setEmailConfirmed = true;
      })
      .addCase(setEmailConfirmed.fulfilled, (state, action) => {
        state.loading.setEmailConfirmed = false;
        state.data.isPublic = action.payload;
      })
      .addCase(setEmailConfirmed.rejected, (state, action) => {
        state.loading.setEmailConfirmed = false;
        state.error.setEmailConfirmed = action.error;
      });
  },
});

export const { setData, clearData, setLoggingIn } = user.actions;

export default user.reducer;
